import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"

const NotFound = () => {
  return (
    <React.Fragment>
      <Header header={{ templateId: `basic`, links: [] }} />
      <div id="skip-nav" className="" style={{ minHeight: `90vh` }}>
        <div className="container py-10 text-center">
          <h1>Page not found</h1>
          <p>
            <Link to="/">Head home</Link>
          </p>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default NotFound